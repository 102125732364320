import React, { createContext, useEffect, useState } from "react";

export const ShopContext = createContext(null);

const ShopContextProvider = (props) => {

  const [products,setProducts] = useState([]);
  
  const getDefaultCart = () => {
    let cart = {};
    for (let i = 0; i < 300; i++) {
      cart[i] = 0;
    }
    return cart;
  };
  const getDefaultFav = () => {
    let fav = {};
    for (let i = 0; i < 300; i++) {
      fav[i] = 0;
    }
    return fav;
  };
  // const getDefaultOrders = () => {
  //   let order = {};
  //   for (let i = 0; i < 300; i++) {
  //     order[i] = 0;
  //   }
  //   return order;
  // };

  const [cartItems, setCartItems] = useState(getDefaultCart());
  const [favItems, setFavItems] = useState(getDefaultFav());
  // const [orders, setOrders] = useState(getDefaultOrders());

  useEffect(() => {
    fetch('http://localhost:4000/allproducts') 
          .then((res) => res.json()) 
          .then((data) => setProducts(data))

    if(localStorage.getItem("auth-token"))
    {
      fetch('http://localhost:4000/getcart', {
      method: 'POST',
      headers: {
        Accept:'application/form-data',
        'auth-token':`${localStorage.getItem("auth-token")}`,
        'Content-Type':'application/json',
      },
      body: JSON.stringify(),
    })
      .then((resp) => resp.json())
      .then((data) => {setCartItems(data)});

      
      fetch('http://localhost:4000/getfav', {
      method: 'POST',
      headers: {
        Accept:'application/form-data',
        'auth-token':`${localStorage.getItem("auth-token")}`,
        'Content-Type':'application/json',
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((data) => {setFavItems(data)})
      .catch((error) => {
        console.log(error)
      })


//       fetch('http://localhost:4000/getorders', {
//       method: 'POST',
//       headers: {
//         Accept:'application/form-data',
//         'auth-token':`${localStorage.getItem("auth-token")}`,
//         'Content-Type':'application/json',
//         "Access-Control-Allow-Origin": "*",
//       },
//       body: JSON.stringify(),
//     })
//       .then((response) => response.json())
//       .then((data) => {setOrders(data)})
//       .catch((error) => {
//         console.log(error)
//       })
    }
}, [])
  
  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = products.find((product) => product.id === Number(item));
        totalAmount += cartItems[item] * itemInfo.new_price;
      }
    }
    return totalAmount;
  };
  
  const getTotalCartAmountWithShipping = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = products.find((product) => product.id === Number(item));
        totalAmount += cartItems[item] * itemInfo.new_price;
      }
    }
    return totalAmount + 40;
  };

  const getTotalCartItems = () => {
    let totalItem = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        totalItem += cartItems[item];;
      }
    }
    return totalItem;
  };
  const getTotalFavItems = () => {
    let totalFav = 0;
    for (const item in favItems) {
      if (favItems[item] > 0) {
        totalFav += favItems[item];;
      }
    }
    return totalFav;
  };
  // const getTotalOrders = () => {
  //   let totalOrders = 0;
  //   for (const item in orders) {
  //     if (orders[item] > 0) {
  //       totalOrders += orders[item];;
  //     }
  //   }
  //   return totalOrders;
  // };

  // const addToOrders = (itemId) => {
  //   setOrders((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
  //   if(localStorage.getItem("auth-token"))
  //   {
  //     fetch('http://localhost:4000/addtoorders', {
  //     method: 'POST',
  //     headers: {
  //       Accept:'application/form-data',
  //       'auth-token':`${localStorage.getItem("auth-token")}`,
  //       'Content-Type':'application/json',
  //     },
  //     body: JSON.stringify({"itemId":itemId}),
  //   })
  //     .then((resp) => resp.json())
  //     .then((data) => {console.log(data)});
  //   }
  // };


  const addToCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
    if(localStorage.getItem("auth-token"))
    {
      fetch('http://localhost:4000/addtocart', {
      method: 'POST',
      headers: {
        Accept:'application/form-data',
        'auth-token':`${localStorage.getItem("auth-token")}`,
        'Content-Type':'application/json',
      },
      body: JSON.stringify({"itemId":itemId}),
    })
      .then((resp) => resp.json())
      .then((data) => {console.log(data)});
    }
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
    if(localStorage.getItem("auth-token"))
    {
      fetch('http://localhost:4000/removefromcart', {
      method: 'POST',
      headers: {
        Accept:'application/form-data',
        'auth-token':`${localStorage.getItem("auth-token")}`,
        'Content-Type':'application/json',
      },
      body: JSON.stringify({"itemId":itemId}),
    })
      .then((resp) => resp.json())
      .then((data) => {console.log(data)});
    }
  };

  const addToFav = (itemId) => {
    setFavItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
    if(localStorage.getItem("auth-token"))
    {
      fetch('http://localhost:4000/addtofav', {
      method: 'POST',
      headers: {
        Accept:'application/form-data',
        'auth-token':`${localStorage.getItem("auth-token")}`,
        'Content-Type':'application/json',
      },
      body: JSON.stringify({"itemId":itemId}),
    })
      .then((resp) => resp.json())
      .then((data) => {console.log(data)})
      .catch((error) => {
        console.log(error);
      })
    }
  };

  const removeFromFav = (itemId) => {
    setFavItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
    if(localStorage.getItem("auth-token"))
    {
      fetch('http://localhost:4000/removefromfav', {
      method: 'POST',
      headers: {
        Accept:'application/form-data',
        'auth-token':`${localStorage.getItem("auth-token")}`,
        'Content-Type':'application/json',
      },
      body: JSON.stringify({"itemId":itemId}),
    })
      .then((resp) => resp.json())
      .then((data) => {console.log(data)});
    }
  };

  const contextValue = {products, getTotalCartItems, cartItems, addToCart, removeFromCart, getTotalCartAmount, getTotalCartAmountWithShipping, addToFav, removeFromFav, getTotalFavItems, favItems };
  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
