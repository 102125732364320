import React, { useContext, useState } from "react";
import "./CartItems.css";
import bellaLogo from '../../assets/bella.png'
import { ShopContext } from "../../Context/ShopContext";
import { Link } from "react-router-dom";
import {X} from 'lucide-react';

const CartItems = () => {
  const {products} = useContext(ShopContext);
  const [open, setOpen] = useState(false);
  const [productId, setProductId] = useState();
  const [productName, setProductName] = useState('');
  const {cartItems,removeFromCart,getTotalCartAmount, getTotalCartAmountWithShipping} = useContext(ShopContext);

  const [colors, setColors] = useState(JSON.parse(localStorage.getItem('colorss')) || []);
  console.log(colors)
  return (
    <>
      <div className="flex flex-col justify-center items-center h-20 w-full relative">
        <Link to="/" className="absolute top-0 z-10">
        <img src={bellaLogo} width={150} alt=""  />
        </Link>
        <div className="h-1 w-full bg-primary-color relative top-1"></div>
      </div>
    <div className="cartitems">
      <h1 className="bold text-7xl text-center text-primary-multiply-color mb-5">Cart Items</h1>
      <p className="text-md regular text-gray-500 max-w-[50%] m-auto text-center mb-5">Here you will find all the items you added to the cart, Procceding means you are checking out all the items below.</p>
      {/* <div className="cartitems-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div> */}
      <hr />
      {products.map((e)=>{

        if(cartItems[e.id]>0)
        {
          return  <div>
                    <div className="cartitems-format-main cartitems-format">
                      <img className="border border-black rounded" src={e.image} height={320} width={320} alt="" />
                      <p className="text-primary-multiply-color bold text-xl lg:text-3xl">{e.name}</p>
                      <p className="bold text-lg text-primary-color">L.E {e.new_price}</p>
                      <button className="cartitems-quantity">{cartItems[e.id]}</button>
                      <p className="bold text-lg text-primary-color">L.E {e.new_price*cartItems[e.id]}</p>
                      <button className="p-2 w-20 md:w-40 bg-primary-multiply-color text-white rounded regular hover:scale-105 transition-all duration-300 ease-in-out" onClick={() => {setOpen(!open); setProductId(e.id); setProductName(e.name);}}>Remove items</button>
                    </div>
                     <hr />
                  </div>;
        }
        return null;
      })}
                      {/*  */}
                      <div className="flex justify-center items-center flex-col my-5">
                      </div>
                      <div className={`${open ? 'flex' : 'hidden'} flex-col justify-center gap-2 my-2 items-center transition-all duration-500`}>
                        {colors.map((t) => (
                          t.split(':')[0] === productName ?
                            <button className="text-sm flex relative justify-center items-center w-60 py-3 bg-primary-multiply-color rounded text-white">{t} <X className="absolute right-3 hover:scale-[1.2] transition-all duration-200" onClick={() => {removeFromCart(productId);localStorage.setItem('colorss', JSON.stringify(colors.filter((i => v => v !== t || --i)(1)))); setColors(JSON.parse(localStorage.getItem('colorss')) || [])}}/> </button> : ''
                        ))}
                         
                      </div>
      <div className="flex w-full text-primary-multiply-color my-5">{localStorage.getItem('colors')}</div>
      
      <div className="cartitems-down">
        <div className="cartitems-total">
          <h1>Cart Totals</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Subtotal</p>
              <p>L.E {getTotalCartAmount()}</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Shipping Fee</p>
              <p>L.E 40</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <h3>Total</h3>
              <h3>L.E {getTotalCartAmountWithShipping()}</h3>
            </div>
          </div>
          <Link to="/checkout">
          <button className=" bg-primary-multiply-color text-white regular rounded p-3">Proceed to Checkout</button>
          </Link>
        </div>
        <div className="cartitems-promocode">
          <p>If you have a promo code, Enter it here</p>
          <div className="cartitems-promobox">
            <input type="text" placeholder="promo code" />
            <button>Submit</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CartItems;
