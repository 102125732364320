import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';
import img from '../assets/accessories-head.jpeg';

gsap.registerPlugin(ScrollTrigger);
const Description = () => {
  useEffect(() => {
    gsap.from('.desc-t', {scrollTrigger: {
      trigger: '.desc-t',
      toggleActions: 'play none none reverse',
      start: 'top 70%',
    }, opacity: 0, x: -200})
    gsap.from('.sld-image', {scrollTrigger: {
      trigger: '.sld-image',
      toggleActions: 'play none none reverse',
      start: 'top 70%',
    }, opacity: 0, x: -200, delay: 1})
    gsap.from('.sld-border', {scrollTrigger: {
      trigger: '.sld-border',
      toggleActions: 'play none none reverse',
      start: 'top 70%',
    }, height: 0, width: 0, duration: 1, ease: 'back.inOut'})
    gsap.from(['.desc-tt', '.desc-p'], {scrollTrigger: {
      trigger: '.desc-tt',
      toggleActions: 'play none none reverse',
      start: 'top 80%',
    }, opacity: 0, y: 200})
  }, [])
  return (
    <div className="h-fit bg-white w-full flex modon-desc">
      <section className="w-1/2 h-[110vh] flex flex-col justify-center items-center desc1">
        <h1 className="bold text-primary-color text-4xl m-5 text-center desc-t">Wide-Variety of Accessories for all Kinds of Tastes</h1>
        <div className="sc-desc w-full flex justify-around items-center">
          <h1 className="bold text-primary-color text-2xl m-3 desc-tt">Mission</h1>
          <h1 className="bold text-primary-color text-2xl m-3 desc-tt">Vision</h1>
        </div>
        <div className="sc-desc w-full flex justify-around items-center">
          <h1 className="regular text-primary-color text-center text-lg mx-10 desc-p w-1/2 h-full">To Be egypt's #1 Jewelry, Accessory & Perfume providor and the go to shop for any kind of need a client might want.</h1>
          <h1 className="regular text-primary-color text-center text-lg mx-10 desc-p w-1/2 h-full">To have branchs all over egypt so that we are close to our customers everywhere and reachable anytime.</h1>
        </div>
      </section>
      <section className="w-1/2 h-[110vh] flex justify-center items-center relative desc2">
        <div className="sld-image h-[80%] w-3/4 relative rounded-t-full">
          <img src={img} alt="" className='h-full w-full rounded-t-full object-cover' />
        </div>
        <div className="sld-border border h-[80%] w-3/4 rounded-t-full absolute left-16 top-10 border-primary-multiply-color">
        </div>
      </section>
    </div>
  )
}

export default Description