export const PROJECTS = [
  { name: "Perfumes", link: "/" },
  { name: "Accessories", link: "/" },
  { name: "Jewelry", link: "/" },
  { name: "HandBags", link: "/" },
  { name: "Other", link: "/" },


];
export const QuickLinks = [
  { name: "About us", link: "/" },
  { name: "Home Page", link: "/" },
  { name: "Contact Us", link: "/" },
  { name: "Shop", link: "/" },

];
