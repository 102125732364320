import React, { Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import './App.css'
import App from './App';
import ShopContextProvider from './Context/ShopContext';
import './i18n'
import SplashScreen from './Pages/SplashScreen';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
function RootComponent() {
  const [showApp, setShowApp] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowApp(true);
    }, 3000); // 3 seconds
  }, []);
  // useEffect(() => {
  //   const cursorDot = document.querySelector('.cursor-dot');
  //   const cursorOutline = document.querySelector('.cursor-outline');
  //   window.addEventListener('mousemove', function(e) {
  //    const posX = e.clientX;
  //    const posY = e.clientY;

  //    cursorDot.style.left = `${posX}px`;
  //    cursorDot.style.top = `${posY}px`;
    
  //   cursorOutline?.animate({
  //     left: `${posX}px`,
  //     top: `${posY}px`
  //   }, {duration: 1000, fill: "forwards", });
  //   })
  
  // })
  return showApp ? (
    <App />
  ) : (
    <SplashScreen />
  )
}
root.render(
      <Suspense fallback={<SplashScreen />}>
    <ShopContextProvider>
      <RootComponent />
      {/* <div className="cursor-dot z-50"></div> */}
      {/* <div className="cursor-outline z-50"></div> */}
    </ShopContextProvider>
      </Suspense>
);
