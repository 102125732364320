import React, {useLayoutEffect} from 'react'
import CartItems from '../Components/CartItems/CartItems'

const Cart = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <div>
      <div className="ob"></div>
      <CartItems/>
    </div>
  )
}

export default Cart
