import { Facebook, Instagram } from "lucide-react";

const SocialIcons = () => {
  return (
    <div className="text-primary">
        <span
          className="p-2 cursor-pointer inline-flex items-center
        rounded-full bg-gray-300 mx-1.5 text-xl hover:text-gray-100 hover:bg-blue-800
        duration-300 "
        >
            <Facebook/>
        </span>
        <span
          className="p-2 cursor-pointer inline-flex items-center
        rounded-full bg-gray-300 mx-1.5 text-xl hover:text-gray-100 hover:bg-gradient-to-br hover:from-purple-700 hover:to-pink-700
        duration-300 "
        >
            <Instagram/>
        </span>
    </div>
  );
};

export default SocialIcons;