import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './CSS/UserPage.css';
const UserPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [users, setUsers] = useState();

  useEffect(() => {
      // getUsers();
   fetch('http://localhost:4000/getuser') 
  .then((res) => res.json()) 
  .then((data) => setUsers(data))

  }, [])
  const userr = users?.find((e) => e.email === user.email);
// console.log(users);
  return (
    <div className='h-[170vh] flex justify-center items-center flex-col bg-gradient-to-br from-white to-[#ffb6bd]'>
      <h1 className='bold text-primary-multiply-color text-7xl my-14'>User Profile</h1>
<main class="profile">
  <div class="profile-bg"></div>
  <section class="container">
    <aside class="profile-image">
      <a class="camera" href="/">
                    <i class="fas fa-camera"></i>
                </a>
    </aside>
    <section class="profile-info">
    <h1 className='bold first-name text-black'>{user && userr?.firstname}
          </h1>
    <h1 className='bold second-name text-black'>{user && userr?.lastname}
          </h1>
      <h2>ABOUT</h2>
      <p className='regular lg:text-xl text-md'>
      {user && `User was create on: ${userr?.date.split('T')[0]}`}
      </p>
      <p className='regular lg:text-xl text-md my-5'>
      {user && `User E-Mail: ${userr?.email}`}
      </p>
      <p className='regular lg:text-xl text-md'>
      {user && `User Number: ${userr?.number}`}
      </p>
      <aside class="social-media-icons">
        <Link to="/">
                        <i class="fab fa-twitter"></i>
                    </Link>
        <Link to="/">
                        <i class="fab fa-codepen"></i>
                    </Link>
        <Link to="/">
                        <i class="fab fa-github"></i>
                    </Link>
        <Link to="/">
                        <i class="fab fa-medium"></i>
                    </Link>
      </aside>
    </section>
  </section>
  <section class="statistics">
    <button class="icon arrow left"></button>
    <button class="icon arrow right"></button>
    <p><strong>17</strong> Orders</p>
    <p><strong>184</strong> Mails Sent</p>
            {localStorage.getItem('auth-token')
  ?<button onClick={()=>{localStorage.removeItem('auth-token');localStorage.removeItem('user');window.location.replace("/");}} className='border border-red-600 p-3 rounded text-red-600 hover:scale-105 transition-all duration-300'>LOGOUT</button>
  :<Link to='/login' style={{ textDecoration: 'none' }}><button className='border border-green-600 p-3 rounded text-green-600 hover:scale-105 transition-all duration-300' onClick={() => window.location.replace("/login")}>LOGIN</button></Link>}
  </section>
  <button class="icon close"></button>
</main>

      {/* <div className='flex bold text-3xl text-primary-multiply-color'>
        {user &&           
      users?.map((m) => (
            m.email === user.email ?
            <div className="flex flex-col">
               <h1>Name: {m.userName}</h1> 
               <h1>Email: {m.email}</h1>
            </div> : ''
          ))}</div> */}
      </div>
  )
}

export default UserPage