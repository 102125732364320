import React, { useContext, useEffect } from "react";
import "./ProductDisplay.css";
import { ShopContext } from "../../Context/ShopContext";
import { Star, StarOff } from "lucide-react";
import { useState } from "react";


const ProductDisplay = (props) => {
  const {product} = props;
  const [productImage, setProductImage] = useState(product.image);
  const {addToCart, removeFromCart, addToFav, removeFromFav, cartItems, favItems} = useContext(ShopContext);
  const [select, setSelection] = useState(`${product.name}: Original `);
  const selection = `${product.name}: ${select} | `;
  const [selects, setSelects] = useState(() => JSON.parse(localStorage.getItem('colorarray')) || []);
  let selections = JSON.stringify(selects);
  localStorage.setItem('colorarray', selections);
  // string[0] = prompt("New");
  localStorage.setItem('colorss', JSON.stringify(selects));
  var storedColors = JSON.parse(localStorage.getItem('colorss'));

  console.log(storedColors);
  
  // product.select = select;
  const colors = localStorage.getItem('colors');
  const color = colors !== null ? (colors + selection) : selection;
  // const colorReverse = colors !== null ? colors.pop() : console.log('nothing to remove');
  const goBack = () => {
    window.history.back()
  }
  const setImageColor = (i, index) => {
      setTimeout(() => {
      setSelection(`${product.name}: ${i}`)
      setProductImage(product.images[0][index])
    }, 500)
  }
  const imageTransition = () => {
    const image = document.getElementById("image");
    image.classList.add("opacity-0")
    setTimeout(() => {
      image.classList.remove("opacity-0")
      image.classList.add("opacity-1")
    }, 500)
  }
  return (
    <div className="flex flex-col justify-center items-center bg-white">
      {/* <div className="h-0 w-0 hidden">
        <Checkout selects={selects}/>
      </div> */}
      <div className="flex h-screen product-display justify-center items-center">
      <div className="flex justify-center items-center w-[55%] gap-4 m-5 product-image">
        <div className="flex flex-col h-[75%] justify-start gap-2 small-images">
          {product.images.map((image) => (
            image.map((img, index) => (
              <img key={index} src={img} onClick={() => {
                setTimeout(() => {
                  setProductImage(img);
                }, 500)
                setImageColor(product.colors[index],index);
                imageTransition();
              }}  
              alt="img" className="box w-10 h-10 rounded shadow-2xl transition-all duration-500" />
            ))
          ))}
        </div>
        <div className="h-[75%] flex justify-center items-center shadow-2xl rounded-2xl w-3/4 product-big-image relative">
          <img className={`h-full rounded-2xl transition-all duration-500`} id="image" src={productImage} alt="img" />
          {productImage !== product.image ? <img src={product.image} id="image" onClick={() => {
            setTimeout(() => {
              setProductImage(product.image)
              setSelection("Original")
            }, 500)
            imageTransition();
            }} alt="img" className="absolute transition-all duration-500 h-32 w-28 rounded-2xl shadow-2xl top-5 right-5"/> : ''}
        </div>
      </div>
      <div className="flex flex-col w-[45%] m-5 lg:px-0 px-10 gap-5 product-data">
        <h1 className="text-5xl text-primary-multiply-color bold">{product.name}</h1>
        <div className="rating flex w-full justify-start items-center gap-2">
        <Star color="black" fill="#9F2B68fb" size={20}/>
            <Star color="black" fill="#9F2B68fb"size={20}/>
            <Star color="black" fill="#9F2B68fb"size={20}/>
            <Star color="black" fill="#9F2B68fb"size={20}/>
            <StarOff color="black"size={20} fill="#9F2B68fb"/>
            <div className="bold text-primary-multiply-color">(122)</div>
        </div>
        <h1 className="text-primary-color regular data-parag">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias culpa voluptate eveniet eaque nesciunt laudantium cum mollitia impedit consectetur rerum tempora suscipit temporibus officia quaerat nemo id laboriosam illum libero neque, nobis ad vitae, iusto dignissimos. Eum earum repellat officiis natus Libero cumque architecto amet ad officiis ut accusantium beatae.</h1>
        <h1 className="text-primary-multiply-color text-2xl bold">L.E {product.new_price}</h1>
        {product.category === "accessories" ? '' : <div className="flex w-full justify-start gap-20 items-center variants">

        <div className="flex flex-col justify-center items-center gap-3 model">
            <h1 className="bold text-primary-multiply-color text-2xl">Size:</h1>
            <div className="flex justify-center items-center w-3/4 model-btns">
                <button 
                className="bg-primary-multiply-color rounded hover:bg-transparent hover:border hover:border-primary-multiply-color transition-all duration-700 hover:text-primary-multiply-color regular p-2 text-white"
                >{product.size}</button>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-3 model">
            <h1 className="bold text-primary-multiply-color text-2xl">Select Color:</h1>
            <div className="flex flex-wrap gap-4 justify-center items-center w-full model-btns">
              {product.colors.map((c, index) => (
                <button 
                className={`${ select === c ? 'bg-white border border-primary-multiply-color text-primary-multiply-color hover:text-white hover:bg-primary-multiply-color transition-all duration-500 p-2 regular rounded' : 'bg-primary-multiply-color rounded hover:bg-transparent hover:border hover:border-primary-multiply-color transition-all duration-500 hover:text-primary-multiply-color regular p-2 text-white '}`}
                  key={index} value={c} onClick={() => {
                    setImageColor(c, index);
                    imageTransition();
                  }}>{c}</button>
              ))}
                
            </div>  
          </div>
</div>}

        {/* {cartItems[product.id] > 0 ? 
        <div className="flex justify-start gap-5 items-center">
          <button className="bg-primary-multiply-color rounded hover:bg-transparent hover:border hover:border-primary-multiply-color transition-all duration-700 hover:text-primary-multiply-color w-20 regular p-2 text-white" onClick={()=>{selects.splice(-1);removeFromCart(product.id);localStorage.setItem('colors', colors.replace(`${selection}`, ''))}}>Remove</button>
          <h1 className="bold text-3xl text-primary-multiply-color">{cartItems[product.id]}</h1>
          <button className="bg-primary-multiply-color rounded hover:bg-transparent hover:border hover:border-primary-multiply-color transition-all duration-700 hover:text-primary-multiply-color w-20 regular p-2 text-white" onClick={()=>{select === 'Original' ? selects.push("Original") : selects.push(select);addToCart(product.id); localStorage.setItem('colors', color)}}>Add</button>
        </div>
         : */}
         <button className="bg-primary-multiply-color rounded hover:bg-transparent hover:border hover:border-primary-multiply-color transition-all duration-700 hover:text-primary-multiply-color w-1/2 regular p-2 text-white" onClick={()=>{select === 'Original' ? selects.push("Original") : selects.push(select);addToCart(product.id);}}>Add to Cart</button>
        {/* } */}
         {favItems[product.id] > 0 ? 
        // <div className="flex justify-start gap-5 items-center">
          <button className="bg-primary-multiply-color rounded hover:bg-transparent hover:border hover:border-primary-multiply-color transition-all duration-700 hover:text-primary-multiply-color w-1/2 regular p-2 text-white" onClick={()=>{removeFromFav(product.id)}}>Remove from Favorites</button>
        // </div>
         :<button className="bg-primary-multiply-color rounded hover:bg-transparent hover:border hover:border-primary-multiply-color transition-all duration-700 hover:text-primary-multiply-color w-1/2 regular p-2 text-white" onClick={()=>{addToFav(product.id)}}>Add to Favorites</button>}
      </div>
      </div>
      <button className="m-5 p-2 rounded bg-black text-white" onClick={goBack}>Previous Page</button>

    </div>
  );
};

export default ProductDisplay;
