export const cards = [
  {
    url: require("../../assets/accessories-head.jpeg"),
    title: "Accesso ries",
    link: "/accessories",
    id: 1,
  },
  {
    url: require("../../assets/perfumes-head.jpeg"),
    title: "Perfumes",
    link: "/fragrances",
    id: 2,
  },
  {
    url: require("../../assets/make-up-head.jpeg"),
    title: "Make Up",
    link: "/make-up",
    id: 3,
  },
  {
    url: require("../../assets/handbags-head.jpeg"),
    title: "Hand Bags",
    link: "/hand-bags",
    id: 3,
  },
  {
    url: require("../../assets/skin-care-head.jpeg"),
    title: "Skin & Hair Care",
    link: "/skin-hair-care",
    id: 4,
  },
];