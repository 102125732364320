import React, { useContext } from "react";
import "./CartItems.css";
import cross_icon from "../Assets/cart_cross_icon.png";
import { ShopContext } from "../../Context/ShopContext";

const FavItems = () => {
  const {products} = useContext(ShopContext);
  const {favItems,removeFromFav} = useContext(ShopContext);

  return (
    <div className="cartitems flex flex-col justify-center items-center gap-5">
      <div className="bold text-primary-multiply-color text-5xl">Favorite Items</div>
      <div className="cartitems-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {products.map((e)=>{

        if(favItems[e.id]>0)
        {
          return  <div>
                    <div className="cartitems-format-main cartitems-format">
                      <img className="cartitems-product-icon" src={e.image} alt="" />
                      <p cartitems-product-title>{e.name}</p>
                      <p>${e.new_price}</p>
                      <button className="cartitems-quantity">{favItems[e.id]}</button>
                      <p>${e.new_price*favItems[e.id]}</p>
                      <img onClick={()=>{removeFromFav(e.id)}} className="cartitems-remove-icon" src={cross_icon} alt="" />
                    </div>
                     <hr />
                  </div>;
        }
        return null;
      })}
    </div>
  );
};

export default FavItems;
