import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Flag from 'react-flagkit';

import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@radix-ui/react-select';
const LangSwitcher = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Get the user's preferred language or use a default language
    const userLanguage = localStorage.getItem('userLanguage') || 'ar';

    // Set the initial language and direction
    i18n.changeLanguage(userLanguage);
    document.documentElement.dir = userLanguage === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.lang = userLanguage;

  }, [i18n]);

  const changeLanguage = (language) => {
    // Update the user's language preference
    localStorage.setItem('userLanguage', language);

    // Change the language and direction
    i18n.changeLanguage(language);
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.lang = language;

  };

  return (
    <div>
      <Select
        onValueChange={(e) => changeLanguage(e)}
        value={i18n.language}
      >
        <SelectTrigger className='border-none cursor-pointer hover:border-none flex justify-center items-center glass-nav'>
        <SelectValue className='rounded'>{i18n.language === 'en'? <Flag country='GB' size={25} className='rounded'/>:<Flag country='EG' className='rounded' size={25}/>}</SelectValue> 
        {/* <ArrowDownUpIcon height={12} /> */}
        </SelectTrigger>
        <SelectContent className='w-full glas-nav rounded-b transition-all duration-300'>
        <SelectGroup className='flex flex-col cursor-pointer justify-center items-center py-2 gap-2 w-full transition-all duration-300'>
          <SelectItem value="ar" className='rounded'><Flag country='EG' className='rounded' size={25}/></SelectItem>
          <SelectItem value="en" className='rounded'><Flag country='GB' className='rounded' size={25}/></SelectItem>
        </SelectGroup>
      </SelectContent>
      </Select>
    </div>
  );
};

export default LangSwitcher;

