import { Label } from "@radix-ui/react-label"
import { Checkbox } from "@radix-ui/react-checkbox"
import { SewingPinFilledIcon } from "@radix-ui/react-icons"
import emailjs from "@emailjs/browser"
import { useRef } from "react"

const ContactPage = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm("service_nej6dxf","template_7kc7bks",form.current, "yF9X9hXsV87yTNt_E").then((result) => {
      console.log(result.text);
      console.log("message sent");
    },
    (error) => {
      console.log(error.text);
    }
  );
};

  return (
    <div className="contact-page w-full h-fit flex flex-col justify-center items-center">
       <div className="contact0 relative h-[90vh] bg-primary-multiply-color w-full flex flex-col justify-center items-center">
    <h1 className="regular md:text-2xl text-lg z-10">Get in Touch</h1>
    <h1 className="bold text-5xl m-3 z-10">Contact Us</h1>
    <p className="light text-center md:text-lg text-sm z-10">Please feel free to get in touch with our Customer support<br /> team for any request or inquiry.</p>
       </div>
      <div className="cont-cont h-fit w-full flex bg-white text-primary-color">
      <div className="contact2 h-[220vh] w-full flex flex-col justify-center items-center regular">
        <h1 className="regular md:text-3xl text-2xl flex contact-header text-primary-multiply-color mb-3 z-10 my-10">WE'D LOVE TO HEAR FROM YOU</h1>
          <form className="flex flex-col justify-start gap-5 py-4 light w-3/4" ref={form} onSubmit={sendEmail}>
            <div className="flex md:flex-row flex-col justify-start gap-5 py-4 light">
             <div className="flex flex-col justify-start gap-5 md:w-1/2 w-full">
              <div className="flex flex-col justify-start gap-2">
                <Label htmlFor="fullname" className="regular">FULL NAME</Label>
                <input id="fullname" className="h-14" placeholder="Enter Full Name" name="user_name" required />
              </div>
              <div className="flex flex-col justify-start gap-2">
                <Label htmlFor="email" className="regular">E-MAIL</Label>
                <input id="email" className="h-14" placeholder="Enter E-Mail" name="user_email" required />
              </div>
              <div className="flex flex-col justify-start gap-2 relative">
                <Label htmlFor="email" className="regular">PHONE NUMBER</Label>
                <div className="grid grid-cols-3 gap-4">
                  <select name="user_code">
                    <option value="+20">+20</option>
                    <option value="+966">+966</option>
                  </select>
                  <input
                    id="phonenumber"
                    placeholder="Enter Your Phone Number"
                    required
                    className="col-span-2 h-14"
                    name="user_number"
                  />
                </div>
                </div>
          </div>
          <div className="flex flex-col justify-start gap-5 md:w-1/2 w-full">
          <div className="flex flex-col justify-start gap-2 md:h-full h-40">
                <Label htmlFor="message" className="regular">Message</Label>
                <textarea id="message" name="message" className="h-full" placeholder="Enter Your Message..." required />
              </div>
          </div>
            </div>
            <h1 className="flex justify-center items-center gap-2">
            <Checkbox className="bg-primary-multiply-color h-4 w-4" />
            Accept Receiving Emails about Bella News & Offers
            </h1>
              <input type="submit" value="SUBMIT" className="md:w-1/2 w-full m-auto regular transition-all duration-500 my-5 bg-primary-multiply-color p-2 rounded-[5px] text-white hover:bg-white hover:border hover:border-primary-multiply-color hover:text-primary-multiply-color"></input>
            </form>
            <div className="w-full my-10 flex justify-center items-center">
              <iframe title="map" className="rounded-2xl" width="70%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Family%20Mall+(Bella%20Boutique)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps tracker sport</a></iframe>
              </div>
            <h1 className="bold text-2xl">Come Visit Us</h1>
            <SewingPinFilledIcon color="var(--primary-multiply-color)" className="m-3" height={20} width={20} />
            <p className="light md:m-0 m-5 text-center">Family Mall, 1st District, 6th of October, Giza</p>
            <p className="light">+20</p>
            <div className="h-0.5 w-10 m-5 bg-primary-color"></div>
            <p className="light">bella.eg@bellastore.com</p>
      </div>
      </div>
    </div>
  )
}

export default ContactPage