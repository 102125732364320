import React, { useContext } from 'react'
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay'
import RelatedProducts from '../Components/RelatedProducts/RelatedProducts'
import bellaLogo from '../assets/bella.png'
import { useParams, Link } from 'react-router-dom'
import { ShopContext } from '../Context/ShopContext'

const Product = () => {
  const {products} = useContext(ShopContext);
  const {productId} = useParams();
  const product = products.find((e)=>e.id === Number(productId));
  return (
    <div>
      <div className="ob"></div>
      <div className="flex flex-col justify-center items-center h-20 w-full relative">
        <Link to="/" className="absolute top-0 z-10">
        <img src={bellaLogo} width={150} alt=""  />
        </Link>
        <div className="h-1 w-full bg-primary-color relative top-1"></div>
      </div>
      {product && <ProductDisplay product={product}/>}
      {product?.category && <RelatedProducts category={product.category} />}
    </div>
  )
}

export default Product
