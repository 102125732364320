import bellaLogo from '../assets/bella.png';
import { Link } from 'react-router-dom';
const NotFound = () => {
  return (
    <div className="flex flex-col h-screen w-full justify-center items-center bg-white text-black">
          <div className="flex flex-col justify-center items-center h-20 w-full relative">
      <Link to="/" className="absolute top-0 z-10">
      <img src={bellaLogo} width={150} alt=""  />
      </Link>
      <div className="h-1 w-full bg-primary-color relative top-1"></div>
    </div>
      <h1 className="regular text-6xl text-primary-color m-3">404 Error</h1>
      <p className="light">The page you're looking for was not found</p>
    </div>
  )
}

export default NotFound