export const accessoriesFilter = [
  {
    title: "Bracelets",
    cat: "bracelets",
    id: 1,
  },
  {
    title: "Rings",
    cat: "rings",
    id: 2,
  },
  {
    title: "Earrings",
    cat: "earrings",
    id: 3,
  },
];
export const handbagsFilter = [
  {
    title: "Large",
    cat: "large",
    id: 1,
  },
  {
    title: "Medium",
    cat: "medium",
    id: 2,
  },
  {
    title: "Small",
    cat: "small",
    id: 3,
  },
];
export const makeupFilter = [
  {
    title: "Rogue",
    cat: "rogue",
    id: 1,
  },
  {
    title: "Blush",
    cat: "blush",
    id: 2,
  },
  {
    title: "Nails",
    cat: "nails",
    id: 3,
  },
];
export const skinhairFilter = [
  {
    title: "Hair",
    cat: "hair",
    id: 1,
  },
  {
    title: "Skin",
    cat: "skin",
    id: 2,
  },
  {
    title: "Additional",
    cat: "additional",
    id: 3,
  },
];
export const fragrancesFilter = [
  {
    title: "Oud",
    cat: "oud",
    id: 1,
  },
  {
    title: "Arabian",
    cat: "arabian",
    id: 2,
  },
  {
    title: "Womens",
    cat: "womens",
    id: 3,
  },
  {
    title: "Mens",
    cat: "mens",
    id: 3,
  },
];