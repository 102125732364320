import React, { useEffect, useState } from "react";
import "./CSS/ShopCategory.css";
import Item from "../Components/Item/Item";
import { Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import bellaLogo from '../assets/bella.png'
import { useLocation } from "react-router-dom";
import { accessoriesFilter, makeupFilter, handbagsFilter, skinhairFilter,fragrancesFilter } from "./filters";


const ShopCategory = (props) => {

    const location = useLocation();
    const {brand} = location.state;
    console.log(brand);


  const [filters, setFilters] = useState(props.category === "accessories" ? accessoriesFilter : props.category === "fragrances" ? fragrancesFilter : props.category === "make-up" ? makeupFilter : props.category === "hand-bags" ? handbagsFilter : props.category === "skin-hair-care" ? skinhairFilter : '')
  const [allproducts, setAllProducts] = useState([]);

  const goBack = () => {
    window.history.back()
  }
  const fetchInfo = () => { 
    fetch('http://localhost:4000/allproducts') 
            .then((res) => res.json()) 
            .then((data) => setAllProducts(data))
    }
    useEffect(() => {
      fetchInfo();
    }, [])
    useLayoutEffect(() => {
      window.scrollTo(0, 0)
  }, []);
  const [cat, setCat] = useState("all")
  return (
    <div className="shopcategory flex flex-col justify-center items-center relative">
      <div className="ob mb-10"></div>
      <div className="flex flex-col justify-center items-center h-20 w-full relative">
        <Link to="/" className="absolute top-0 z-10">
        <img src={bellaLogo} width={150} alt=""  />
        </Link>
        <div className="h-1 w-full bg-primary-color relative top-1"></div>
      </div>
      <div className="flex flex-wrap gap-5 justify-around items-center regular my-5 mx-5">
        <h1 className="bold text-primary-multiply-color text-3xl">Filter:</h1>
        <button className="p-2 px-5 rounded-2xl bg-primary-multiply-color text-white transition-all duration-500 hover:bg-white hover:border hover:border-primary-multiply-color hover:text-primary-multiply-color" onClick={() => setCat("all")}>All</button>
        {filters.map((filter) => (
          <button className="p-2 px-5  rounded-2xl bg-primary-multiply-color text-white transition-all duration-500 hover:bg-white hover:border hover:border-primary-multiply-color hover:text-primary-multiply-color" key={filter.id}  onClick={() => setCat(filter.cat)}>{filter.title}</button>
        ))}
      </div>
      <div className="shopcategory-products">
        {allproducts.map((item,i) => {
          if(brand === item.brand) {
            if(props.category===item.category && cat === "all")
            {
              return <Item id={item.id} key={i} name={item.name} image={item.image}  new_price={item.new_price} old_price={item.old_price}/>;
            }
            else if(props.category===item.category && cat === item.category2)
            {
              return <Item id={item.id} key={i} name={item.name} image={item.image}  new_price={item.new_price} old_price={item.old_price}/>;
            }
          } else {
            return null;
          }
        })}
      </div>
      <div className="shopcategory-loadmore" onClick={goBack}>
      <button to='/' style={{ textDecoration: 'none' }}>Explore More Brands</button>
      </div>
    </div>
  );
};

export default ShopCategory;
