import ItemsContainer from "./ItemContainer";
import SocialIcons from "./Social";

const Footer = () => {
  return (
    <footer className="bg-primary-multiply-color text-white">
      <div className="bg-gray-200 h-fit py-5 gap-5 w-full flex flex-wrap justify-around items-center">
        <h1 className="bold md:text-3xl text-2xl text-slate-400"><span className="text-primary-multiply-color">Stay Informed</span> with our <br />latest updates.</h1>
        {/* <div className="newsletter flex justify-center items-center">
          <input type="email" placeholder="Enter your email.." className="md:p-2 p-1 bg-slate-400 placeholder:text-white rounded-tl rounded-bl" />
          <button className="md:h-10 h-8 rounded-tr rounded-br bg-primary-multiply-color">Submit</button>
        </div> */}
        <div className="nl">
          <input type="email" placeholder="Enter your email.." className="p-2 bg-slate-500 placeholder:text-white rounded-xl" />
          <button className="bg-primary-multiply-color p-2 rounded-xl">Submit</button>
        </div>
      </div>
      <ItemsContainer />
      <div
        className="flex flex-wrap justify-around items-center h-24 text-gray-200 bg-primary-multiply-color text-sm"
      >
        <span className="transition-all duration-500 hover:text-white">© 2023 BELLA. All rights reserved.</span>
        <span className="transition-all duration-500 hover:text-white">Terms · Privacy Policy</span>
        <SocialIcons />
      </div>
    </footer>
  );
};

export default Footer;