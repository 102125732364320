import Navbar from "./Components/Navbar/Navbar";
import './App.css'
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Pages/Home";
import Cart from "./Pages/Cart";
import Favorites from "./Pages/Favorites";
import Product from "./Pages/Product";
import Footer from "./Components/Footer/Footer";
import ShopCategory from "./Pages/ShopCategory";
import LoginSignup from "./Pages/LoginSignup";
import SignUp from "./Pages/SignUp";
import AboutPage from "./Components/AboutPage/AboutPage";
import ContactPage from "./Components/ContactPage/ContactPage";
import Checkout from "./Components/Checkout";
import Brand from "./Pages/Brand";
import UserPage from "./Pages/UserPage";
import NotFound from "./Pages/NotFound";


function App() {

  return (
    <div>
      <BrowserRouter>
         <Navbar />
        <Routes>
          <Route path="/" element={<Home gender="all" />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/accessories" element={<Brand category="accessories"/>} />
          <Route path="/accessorie" element={<ShopCategory category="accessories" />} />
          <Route path="/fragrances" element={<Brand category="fragrances" />} />
          <Route path="/fragrance" element={<ShopCategory category="fragrances" />} />
          <Route path="/make-up" element={<Brand category="make-up" />} />
          <Route path="/make-ups" element={<ShopCategory category="make-up" />} />
          <Route path="/hand-bags" element={<Brand category="hand-bags" />} />
          <Route path="/hand-bag" element={<ShopCategory category="hand-bags" />} />
          <Route path="/skin-hair-care" element={<Brand category="skin-hair-care" />} />
          <Route path="/skin-hair-cares" element={<ShopCategory category="skin-hair-care" />} />
          <Route path='/product' element={<Product />}>
            <Route path=':productId' element={<Product />} />
          </Route>
          <Route path="/cart" element={<Cart />} />
          <Route path="/userpage" element={<UserPage />} />
          <Route path="/fav" element={<Favorites />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<LoginSignup/>} />
          <Route path='*' element={<NotFound />}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
