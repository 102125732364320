import React, { useEffect, useState } from 'react'
import Hero from '../Components/Hero/Hero'
import Popular from '../Components/Popular/Popular'
// import Offers from '../Components/Offers/Offers'
import NewCollections from '../Components/NewCollections/NewCollections'
// import InfiniteScroll from '../Components/InfiniteScroll/InfiniteScroll'
import InfiniteScrollText from '../Components/InfiniteScroll/InfiniteScrollText'
import Description from '../Components/Description'
import SideImage from '../Components/SideImage/SideImage'
import HorizontalScroll from '../Components/HorizontalScroll/HorizontalScroll'
// import { useLayoutEffect } from 'react'
import SecondPage from '../Components/SecondRoutesPage/SecondPage'
const Home = () => {

  const [popular, setPopular] = useState([]);
  const [newcollection, setNewCollection] = useState([]);

  const fetchInfo = () => { 
    fetch('http://localhost:4000/popularinwomen') 
            .then((res) => res.json()) 
            .then((data) => setPopular(data))
    fetch('http://localhost:4000/newcollections') 
            .then((res) => res.json()) 
            .then((data) => setNewCollection(data))
    }

    useEffect(() => {
      fetchInfo();
    }, [])
  //   useLayoutEffect(() => {
  //     window.scrollTo(0, 0)
  // });
  return (
    <div>
      <Hero/>
      <InfiniteScrollText isLeft />
      <SecondPage />
      <Popular data={popular}/>
      <Description />
      <NewCollections data={newcollection}/>
      <SideImage />
      <HorizontalScroll />
    </div>
  )
}

export default Home
