import React from 'react'

const InfiniteScrollText = ({isLeft}) => {
  return (
    <div className="w-full mb-20 mt-10 inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
    <ul className={isLeft ? "flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" : "flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll-right"}>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
    </ul>
    <ul className={isLeft ? "flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" : "flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll-right"} aria-hidden="true">
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
        <li>
        <h1 className='empty-text bold text-[3rem]'>BELLA</h1>
        </li>
    </ul>
</div>
  )
}

export default InfiniteScrollText