import React from 'react'
import FavItems from '../Components/CartItems/FavItems'

const Favorites = () => {
  return (
    <div>
      <div className="ob"></div>
      <FavItems/>
    </div>
  )
}

export default Favorites
